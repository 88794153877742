@import "./variables.scss";

html{
    box-sizing: border-box;
    -ms-text-size-adjust: 100%;
    -webkit-text-size-adjust: 100%;
    font-size: 12px;
    font-family: 'Poppins', sans-serif;
    @media (min-width: 1280px){
        font-size: 14px;
    }
    @media (min-width: 1600px){
        font-size: 16px;
    }
}

body {
    margin: 0;
    -webkit-font-smoothing: antialiased;
    background-color: $eCLight !important;
}

.parent-wrapper{
    
    // height: 100vh;
}

// [class^="MuiPopover-root-"], 
// [class*=" MuiPopover-root-"],
// [class*=" MuiTooltip-"]
// {
//     z-index: 9999 !important;
// }